/* global css */
.bg-purple {
  background-color: #19045D;
}

.btn-primary {
  color: white;
  background-color: transparent;
  border-color: #ffff;
  cursor: pointer;
}

.card {
  color: white;
  border-radius: 13px;
}

.card-gradient {
  position: relative;
  border-radius: 12px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  overflow: hidden; /* Ensures content respects the border-radius */
}

.card-gradient::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 12px; /* Match the border radius of the parent */
  padding: 2px; /* Simulate border width */
  background: linear-gradient(180deg, #BD0EA2 0%, #FFFFFF 100%); /* Gradient border */
  mask: 
    linear-gradient(#fff 0 0) content-box, 
    linear-gradient(#fff 0 0);
  -webkit-mask: 
    linear-gradient(#fff 0 0) content-box, 
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
  pointer-events: none;
}

.form-control:focus {
  border-color: #19045D;
  box-shadow: 0 0 0 0.2rem rgba(42, 27, 61, 0.25);
}

.bg-img-position{
  background-color: #19045D;
  /* background-image: url("/mainBg.png"); */
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat
}

.underline{
  margin-right: 1rem;
  background-image: url("/border.png");
  background-repeat: no-repeat;
  background-position: bottom;
  padding-bottom: 1.25rem;
  background-size: contain;
}

.form-control:disabled {
  background-color: #C3C3C3;
  opacity: 1;
  border-color: #C3C3C3;
}

.custom-heading{
  font-size: 52px;
  font-weight: 600;
  line-height: 78px;
  text-align: left;
}

/* Apply the font to all elements */
* {
  font-family: 'Open Sauce Two', sans-serif;
}

.bg-purple {
  background-color: #19045D;
}
.customInput{
  border: 2px solid #000000;
  background-color: transparent;
}
.customInput2{
  border: 2px solid #B0B0B0;
  background-color: transparent;
}
.customWidth{
  max-width: 493px;
}

.form-control:focus {
  border-color: #19045D;
  box-shadow: 0 0 0 0.2rem rgba(42, 27, 61, 0.25);
}

/* Add specific font weights for different elements */
h1, .h1 {
  font-weight: 700;
}

h2, .h2 {
  font-weight: 600;
}

.navbar-brand {
  font-weight: 600;
}

.btn {
  font-weight: 500;
}

.form-label {
  font-weight: 500;
}

.card-body {
  padding: 0;
}

.table-responsive {
  border-radius: 12px;
  min-height: 421px;
  font-size: 15px;
  margin-bottom: 1rem;
  padding-bottom: 1.54rem;
  overflow-x: scroll !important;
}

.table thead {
  box-shadow: 0px 0px 4px 0px #00000040;
  background-color: #E8E8E8; /* Header background color */
}

.table thead th {
  background-color: #E8E8E8;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  background-color: #FFFFFF;
  --bs-table-bg-type: white;
}

.mWebHead{
  padding: 1rem;
  background-color: white;
  box-shadow: 0px 0px 4px 0px #00000040;
  font-size: 14px;
}

.mWebHead h1{
  font-size: 22px;
}

.text-danger{
  font-size: 12px;
  margin-bottom: 14px;
}

.table-striped > tbody > tr:nth-of-type(even) > * {
  background-color: #F4F4F8; /* Even row background color */
}

.table > :not(caption) > * > * {
  padding: 1rem;
}

.table td, .table th {
  text-align: center; /* Center aligns the content */
  vertical-align: middle; /* Vertically centers the content */
  white-space: nowrap; /* Prevent text from wrapping */
}

.position-relative {
  display: inline-block;
}

.position-absolute {
  width: 10px; /* Adjust size for the green dot */
  height: 10px;
}

/* Add these styles to your CSS file */
.bg-purple {
  background-color: #19045D !important;
}

.text-purple {
  color: #19045D !important;
}

.btn-purple {
  background-color: #19045D !important;
  border-color: #19045D !important;
}

.btn-purple:hover {
  background-color: #2d1875 !important;
  border-color: #2d1875 !important;
}

.table-responsive::-webkit-scrollbar {
  height: 8px; /* Height of horizontal scrollbar */
  width: 8px;  /* Width of vertical scrollbar */
}
.table-responsive::-webkit-scrollbar-thumb {
  background-color: #2E09A5; /* Thumb color */
  border-radius: 12px; /* Rounded corners */
}

.table-responsive::-webkit-scrollbar-track {
  background-color: #C6C4C4; /* Track background */
}
/* Sidebar styles */
.sidebar {
  transition: transform 0.3s ease-in-out;
}
/* Hover effect for sidebar menu items */
.hover-bg:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

@media (max-width: 991.98px) {
  .bg-white{
    background-color: #19045D !important;
  }
  .sidebar {
    transform: translateX(-100%);
  }
  
  .sidebar.show {
    transform: translateX(0);
  }

  .wrapper {
    margin-left: 0 !important;
    transition: margin-left 0.3s ease-in-out;
  }
}

@media (min-width: 992px) {
  .container{
    position: relative;
  }
  .wrapper.sidebar-expanded {
    margin-left: 250px;
    transition: margin-left 0.3s ease-in-out;
  }
}
@media (max-width: 390px) {
  .customWidth{
    margin-bottom: 2rem !important;
  }
  .custom-heading{
    margin-top: 6rem !important;
  }
}
@media (max-width: 768px) {
  .underline{
    margin-right: 4px;
    background-image: url("/border.png");
    background-repeat: no-repeat;
    background-position: bottom;
    padding-bottom: 12px;
    background-size: contain;
  }
  .custom-heading{
    font-size: 26px;
    font-weight: 600;
    line-height: 39px;
    text-align: center;
    max-width: 70%;
    margin: 0 auto;
    margin-top: -1rem;
    margin-bottom: 1rem;
  }
  .card{
    margin: 0 1rem;
    text-align: center;
  }
  .card-body div{
    text-align: left;
  }
  .p-5 {
    padding: 2.5rem 1.5rem !important;
  }
  .customWidth{
    max-width: 95%;
    margin: 0 auto;
  }
  .bg-img-position{
    /* background-image: url("/mobilebg.png"); */
  }
  .logoMain > img{
    position: absolute;
    top: 2rem;
    left: 50%;
    transform: translateX(-50%);
  }
  .table-responsive {
    font-size: 13px; /* Reduce font size for better fit */
  }
  .card{
    margin: 0 !important;
  }
  .table thead th,
  .table tbody td {
    padding: 1rem; /* Adjust padding for smaller screens */
  }

  .table thead th {
    white-space: nowrap; /* Prevent text wrapping */
  }
  .table-responsive{
    min-height: 0;
  }
}

/* For Firefox */
@supports (scrollbar-color: auto) {
  .table-responsive {
    scrollbar-color: #2E09A5 #C6C4C4; /* Thumb color and track color */
    scrollbar-width: thin; /* Thin scrollbar */
  }
}

/* Import Open Sauce Two font */
@font-face {
  font-family: 'Open Sauce Two';
  src: url('https://fonts.cdnfonts.com/css/open-sauce-two') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sauce Two';
  src: url('https://fonts.cdnfonts.com/css/open-sauce-two') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sauce Two';
  src: url('https://fonts.cdnfonts.com/css/open-sauce-two') format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sauce Two';
  src: url('https://fonts.cdnfonts.com/css/open-sauce-two') format('woff2');
  font-weight: 700;
  font-style: normal;
}
.cl-internal-uumn80 {
  /* display: inline-block; */
  height: inherit !important;
  width: 100%;
  object-fit: contain;
}